export default function InvoiceItemMain({ item, index }: any) {
  const { part_number, description, quantity } = item;
  return (
    <>
      <div className="grid grid-cols-12 items-center" key={index}>
        <div className="col-span-11 px-1  border-r border-black  text-left ">
          <div className="flex">
            <div className="w-20">{part_number}</div>
            <div className="ml-8">{description}</div>
          </div>
        </div>
        <div className="col-span-1 px-1 border-black text-center">{quantity}</div>
      </div>
    </>
  );
}
