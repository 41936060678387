import { message } from "antd";

import { useRef, useState } from "react";
import fetchDataArps from "../../services/fetchDataArps";
import formatMoney from "../../utils/formatMoney";
import spin from "../../assets/spin.svg";
import { jsPDF } from "jspdf";
import {
  AiOutlineCheck,
  AiOutlinePrinter,
  AiOutlineSync,
} from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { BiLoader } from "react-icons/bi";
import postDataArps from "../../services/postDataArps";
import PreviewDocumentMain from "./components/preview/PreviewDocumentMain";
import PreviewDocumentPrice from "./components/preview/PreviewDocumentPrice";
import PrintDocumentPrice from "./components/print/PrintDocumentPrice";
import PrintDocumentMain from "./components/print/PrintDocumentMain";

import ValueHead from "../../components/shared/ValueHead";
import ReactToPrint from "react-to-print";
export default function Value() {
  const [entries, setEntries] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSyncing, setSyncing] = useState<boolean>(false);
  const [wip, setWip] = useState("");
  const [invoice, setInvoice] = useState("");
  const [tin, setTin] = useState("");
  const [lpo, setLpo] = useState("");
  const [vrn, setVrn] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [printInv, setPrintInv] = useState(false);
  const [printGat, setPrintGat] = useState(false);

  const docRef: any = useRef(null);
  const gatRef: any = useRef(null);

  const [company, setCompany] = useState<any>({
    label: "Karimjee Value Chain Ltd",
    key: 20,
    address1: "Sokoine Drive, P.o Box 409",
    address2: "Dar es salaam",
    address3: "Tel : 051 866815-9, 866352",
    address4: "Fax : 051 866814",
    tin: "TIN : 106-207-674",
    vrn: "VRN : 40-023170-U",
  });
  const [isAllSelected, setAllSelected] = useState(false);

  const handleSyncData = async () => {
    setSyncing(true);
    const uri = `revenue/parts/upload`;
    const data = {};
    const response = await postDataArps(data, uri);
    if (response.success) {
      message.success("Data synched successfully");
    } else {
      message.error("Something went wrong");
    }
    setSyncing(false);
  };

  const handleFetchByWip = async () => {
    setSelected([]);
    setLoading(true);
    const uri = `revenue/parts/search?wip=${wip}&invoice=${invoice}&company=${company?.key}`;
    const response = await fetchDataArps(uri);

    if (response.success === false) {
      message.error(response.message);
      setLoading(false);
      return;
    }
    if (response.payload.length === 0) {
      message.error(`No Records Found`);
      setEntries([]);
      setSelected([]);
      setLoading(false);
      return;
    }
    const filter = response?.payload?.filter(
      (item: any) => item?.net_sale >= 0
    );
    setEntries(filter);

    setLoading(false);
  };

  const handleClearState = async () => {
    setWip("");
    setTin("");
    setVrn("");
    setLpo("");
    setAddress1("");
    setAddress2("");
    setInvoice("");
    setEntries([]);
    setSelected([]);
    setLoading(false);
    setSyncing(false);
    setPrintInv(false);
    setPrintGat(false);
    setAllSelected(false);
    setCompany({
      label: "Karimjee Value Chain Ltd",
      key: 20,
      address1: "Sokoine Drive, P.o Box 409",
      address2: "Dar es salaam",
      address3: "Tel : 051 866815-9, 866352",
      address4: "Fax : 051 866814",
      tin: "TIN : 106-207-674",
      vrn: "VRN : 40-023170-U",
    });
  };

  const handleAddItem = (item: any) => {
    setSelected([...selected, item]);
  };

  const handleRemoveItem = (item: any) => {
    var filtered = selected.filter(function (value: any) {
      return value._id !== item?._id;
    });
    setSelected(filtered);
  };

  const handleAddAll = () => {
    setSelected(entries);
    setAllSelected(true);
  };
  const handleRemoveAll = () => {
    setSelected([]);
    setAllSelected(false);
  };

  const printInvoice = () => {
    setPrintInv(true);
    const doc: jsPDF | any = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
      putOnlyUsedFonts: true,
      floatPrecision: "smart",
    });
    if (docRef.current) {
      docRef.current.style.backgroundColor = "#FFFFFF";
      doc.html(docRef.current, {
        callback: function (doc: any) {
          doc.save(`Invoice-${selected[0]?.wip}.pdf`);
          setPrintInv(false);
        },
        html2canvas: {
          scale: 0.97,
        },
        x: 30,
        y: 30,
        margin: [0, 10, 0, 0],
        width: 595.28,
      });
    }
    console.log("HERE 3");
  };

  const printGate = () => {
    setPrintGat(true);
    const doc: jsPDF | any = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
      putOnlyUsedFonts: true,
      floatPrecision: "smart",
    });

    if (gatRef.current) {
      gatRef.current.style.backgroundColor = "#FFFFFF";
      doc.html(gatRef.current, {
        callback: function (doc: any) {
          doc.save(`GatePass-${selected[0]?.wip}.pdf`);
          setPrintGat(false);
        },
        html2canvas: {
          scale: 0.97,
        },
        x: 30,
        y: 30,
        margin: [0, 50, 0, 0],
        width: 595.28,
      });
    }
  };

  return (
    <>
      <ValueHead />
      <div
        style={{ fontSize: "14px" }}
        className="grid grid-cols-12 gap-0 mx-4 my-2 border rounded-lg"
      >
        <div className="bg-white col-span-2 rounded-l-lg border-r p-4">
          <input
            type="number"
            onChange={(e) => {
              setWip(e.target.value);
            }}
            value={wip}
            placeholder="Enter WIP Number"
            className="border border-gray-400 p-2 rounded-md w-full justify-center items-start focus:border-toyota focus:outline-none"
          />
          <input
            type="number"
            onChange={(e) => {
              setInvoice(e.target.value);
            }}
            value={invoice}
            placeholder="Enter Invoice No."
            className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
          />

          <div className="flex border-t mt-4">
            <button
              onClick={() => handleFetchByWip()}
              className="p-2 bg-toyota hover:bg-blue-900 text-white my-2 mr-1 rounded-md w-full hover:text-white"
            >
              {isLoading ? (
                <div className="flex justify-center items-center pb-1">
                  <img src={spin} alt="spin" height="23px" width="23px" />
                </div>
              ) : (
                <div className=" flex justify-center items-center pb-1">
                  Submit
                </div>
              )}
            </button>
            <button
              onClick={() => handleClearState()}
              className="p-2 bg-white border border-gray-300 hover:border-toyota hover:text-toyota text-gray-800 my-2 ml-1 rounded-md w-full "
            >
              <div className="pb-1">Reset</div>
            </button>
          </div>

          <div className="mb-4">
            <input
              type="text"
              onChange={(e) => {
                setAddress1(e.target.value);
              }}
              value={address1}
              placeholder="Enter Address 1"
              className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
            />

            <input
              type="text"
              onChange={(e) => {
                setAddress2(e.target.value);
              }}
              value={address2}
              placeholder="Enter Address 2"
              className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
            />
            <input
              type="text"
              onChange={(e) => {
                setTin(e.target.value);
              }}
              value={tin}
              placeholder="Enter TIN No."
              className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
            />

            <input
              type="text"
              onChange={(e) => {
                setVrn(e.target.value);
              }}
              value={vrn}
              placeholder="Enter VRN No."
              className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
            />
            <input
              type="text"
              onChange={(e) => {
                setLpo(e.target.value);
              }}
              value={lpo}
              placeholder="Enter LPO No."
              className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
            />
          </div>
          <div className="">
            {entries.length === 0 ? null : (
              <button
                onClick={() => {
                  isAllSelected ? handleRemoveAll() : handleAddAll();
                }}
                className={` flex items-center px-2 py-2  mb-1 w-full border border-gray-300 p-2  hover:border-green-500  ${
                  isAllSelected ? "bg-green-100 " : "hover:bg-gray-100 "
                }`}
              >
                {isAllSelected ? (
                  <button
                    className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-green-600 bg-green-600"
                    onClick={() => handleRemoveAll()}
                  >
                    <AiOutlineCheck color="#FFFFFF" />
                  </button>
                ) : (
                  <button
                    className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-gray-500"
                    onClick={() => handleAddAll()}
                  >
                    <AiOutlineCheck color="#FFFFFF" />
                  </button>
                )}
                <div className="ml-2">Select All</div>
              </button>
            )}
            {entries?.map((entry: any, index: number) => (
              <>
                <button
                  className={`border border-gray-300 p-2 mb-2 w-full hover:border-green-500  ${
                    selected?.includes(entry)
                      ? "bg-green-100 "
                      : "hover:bg-gray-100 "
                  }`}
                  onClick={() =>
                    selected?.includes(entry)
                      ? handleRemoveItem(entry)
                      : handleAddItem(entry)
                  }
                  key={index}
                >
                  <div className="flex">
                    <div className="">
                      {selected?.includes(entry) ? (
                        <button
                          className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-green-600 bg-green-600"
                          onClick={() => handleRemoveItem(entry)}
                        >
                          <AiOutlineCheck color="#FFFFFF" />
                        </button>
                      ) : (
                        <button
                          className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-gray-500"
                          onClick={() => handleAddItem(entry)}
                        >
                          <AiOutlineCheck color="#FFFFFF" />
                        </button>
                      )}
                    </div>
                    <div className="ml-2">
                      <span className="font-bold mr-1">Part no:</span>
                      {entry?.part_number}
                    </div>
                  </div>
                  <div className="my-2 text-left">{entry?.description}</div>
                  <div className="border-t-2 border-gray-500 mt-2 font-bold flex justify-end">
                    {formatMoney(entry?.net_sale ? Number(entry?.net_sale) : 0)}
                  </div>
                </button>
              </>
            ))}
          </div>
        </div>
        <div className="bg-gray-50 col-span-10 h-full rounded-r-lg">
          <div className="flex">
            <div className="w-full">
              <div className="">
                <div className="py-3 px-8  border-b flex justify-between items-center bg-white rounded-t-lg">
                  <div className="">KVCL INVOICES</div>
                  <div className="flex">
                    <button
                      onClick={() => handleSyncData()}
                      className="flex justify-center items-center border p-2 mr-2 text-sm"
                    >
                      <div className="flex justify-center items-center hover:text-toyota">
                        <div className="mr-1">
                          {isSyncing ? <BiLoader /> : <AiOutlineSync />}
                        </div>
                        <div>Sync Data</div>
                      </div>
                    </button>
                    {selected.length === 0 ? (
                      <>
                        <button className="flex cursor-not-allowed justify-center border p-2 items-center focus:outline-none  mr-2 text-sm">
                          <AiOutlinePrinter className="mr-1" /> Print Invoice
                        </button>
                        <button className="flex cursor-not-allowed justify-center border p-2 items-center focus:outline-none  mr-2 text-sm">
                          <AiOutlinePrinter className="mr-1" /> Print Gate Pass
                        </button>
                      </>
                    ) : (
                      <>
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <button className="flex justify-center border p-2 items-center focus:outline-none  mr-2 text-sm hover:text-toyota">
                                <AiOutlinePrinter className="mr-1" /> Print
                                Invoice
                              </button>
                            );
                          }}
                          content={() => docRef.current}
                          documentTitle={`Invoice-${selected[0]?.wip}.pdf`}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-center">
                {selected.length === 0 ? (
                  <div className="h-screen">
                    <div className="flex flex-col justify-center items-center mt-32">
                      <div className="">
                        <BsSearch color="#9ca3af" />
                      </div>
                      <div className="text-gray-400">Nothing to preview</div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div
                      style={{
                        fontSize: "11px",
                      }}
                      ref={docRef}
                    >
                      <PreviewDocumentPrice
                        selected={selected}
                        isGatePass={true}
                        company={company}
                        tin={tin}
                        lpo={lpo}
                        vrn={vrn}
                        address1={address1}
                        address2={address2}
                        isFileCopy={false}
                      />
                      <PreviewDocumentPrice
                        selected={selected}
                        isGatePass={true}
                        company={company}
                        tin={tin}
                        lpo={lpo}
                        vrn={vrn}
                        address1={address1}
                        address2={address2}
                        isFileCopy={true}
                      />
                      <PreviewDocumentMain
                        isGatePass={true}
                        selected={selected}
                        company={company}
                        tin={tin}
                        lpo={lpo}
                        vrn={vrn}
                        address1={address1}
                        address2={address2}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className={`${printInv ? "block" : "hidden"}`}>
                <div
                  ref={docRef}
                  className="bg-white"
                  style={{
                    fontSize: "10px",
                    fontStretch: "inherit",
                    background: "#FFFFFF",
                  }}
                >
                  <PrintDocumentPrice
                    selected={selected}
                    company={company}
                    tin={tin}
                    lpo={lpo}
                    vrn={vrn}
                    address1={address1}
                    address2={address2}
                    isFileCopy={false}
                    isGatePass={true}
                  />
                </div>
              </div>

              <div className={`${printGat ? "block" : "hidden"}`}>
                <div
                  ref={gatRef}
                  className="bg-white"
                  style={{
                    fontSize: "10px",
                    fontStretch: "inherit",
                    background: "#FFFFFF",
                  }}
                >
                  <PrintDocumentPrice
                    selected={selected}
                    company={company}
                    tin={tin}
                    lpo={lpo}
                    vrn={vrn}
                    address1={address1}
                    address2={address2}
                    isFileCopy={true}
                  />
                  <PrintDocumentMain
                    isGatePass={true}
                    selected={selected}
                    company={company}
                    tin={tin}
                    lpo={lpo}
                    address1={address1}
                    address2={address2}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
